import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import "../../blocks/registration/GenreSelection.css";

const GenreSelection = ({ formData = {}, setFormData }) => {
  const navigate = useNavigate();
  const genres = [
    "Pop",
    "Rock",
    "Indie",
    "Música Electrónica",
    "House",
    "Techno",
    "Reggaetón",
    "Rap",
    "Trap",
    "Hip-Hop",
    "R&B",
    "Funk",
    "Jazz",
    "Blues",
    "Soul",
    "Flamenco",
    "Música Clásica",
    "Country",
    "Reggae",
    "Salsa",
    "Cumbia",
    "Bachata",
    "K-Pop",
    "Metal",
    "Punk",
    "Folk",
    "Latin Alternative",
    "Bolero",
  ];

  const [selectedGenres, setSelectedGenres] = useState(formData.genres || []);
  const [errorMessage, setErrorMessage] = useState("");

  const handleGenreChange = (genre) => {
    setSelectedGenres((prev) => {
      if (prev.includes(genre)) {
        const newSelection = prev.filter((g) => g !== genre);
        setErrorMessage(""); // Ocultar mensaje si ya no hay más de 5 seleccionados
        return newSelection;
      } else {
        if (prev.length >= 5) {
          setErrorMessage(
            "Máximo 5 géneros. 🙏 Desmarca uno para seleccionar otro."
          );
          return prev;
        }
        return [...prev, genre];
      }
    });
  };

  const getSession = async () => {
    console.log("Verificando sesión en Supabase...");
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    console.log("Sesión obtenida:", data);
    return data;
  };

  const getUserData = async (userId) => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("user_id", userId)
      .single();

    if (error && error.code !== "PGRST116") {
      console.error("Error obteniendo usuario de Supabase:", error);
      return null;
    }
    return data;
  };

  const saveUserData = async (userId, userEmail) => {
    try {
      const userPayload = {
        user_id: userId,
        email: userEmail,
        top_genres: selectedGenres.join(", "),
        spotify_id: null,
        name: null,
        country: null,
        followers: 0,
        top_artists: null,
        logout_token: null,
        profile_image: null,
        needs_recommendation: false,
      };

      console.log("Datos a guardar en Supabase:", userPayload);
      const existingUser = await getUserData(userId);

      let error = null;
      if (existingUser) {
        const { error: updateError } = await supabase
          .from("users")
          .update(userPayload)
          .eq("user_id", userId);
        error = updateError;
      } else {
        const { error: insertError } = await supabase
          .from("users")
          .insert([userPayload]);
        error = insertError;
      }

      if (error) {
        console.error("Error guardando usuario en Supabase:", error);
        return false;
      }
      console.log("Usuario guardado en Supabase correctamente");
      return true;
    } catch (err) {
      console.error("Error en saveUserData:", err);
      return false;
    }
  };

  const handleNext = async () => {
    try {
      const data = await getSession();
      if (!data || !data.session || !data.session.user) {
        alert("No hay sesión activa. Por favor, inicia sesión.");
        navigate("/");
        return;
      }

      if (selectedGenres.length === 0) {
        alert("Debes seleccionar al menos un género.");
        return;
      }

      console.log("Géneros seleccionados:", selectedGenres);
      if (typeof setFormData === "function") {
        console.log("Ejecutando setFormData...");
        setFormData((prevData) => ({
          ...prevData,
          genres: selectedGenres, // Actualizamos formData con los géneros seleccionados
        }));
      } else {
        console.warn("setFormData no está definido o no es una función.");
      }

      const user = data.session.user;
      const success = await saveUserData(user.id, user.email);
      console.log("Resultado de saveUserData:", success);
      if (success) {
        navigate("/disliked-music-genres");
      } else {
        alert("Hubo un problema al guardar los datos del usuario.");
      }
    } catch (err) {
      console.error("Error en handleNext:", err);
      alert("Hubo un problema al procesar tu solicitud. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="genre-selection-container">
      <h2>
        Selecciona un máximo de 5 géneros que te gustan para ver en directo 🤟
      </h2>
      {/* Mensaje de error dinámico */}
      {errorMessage && <p className="genre-error-message">{errorMessage}</p>}

      <div className="genre-list">
        {genres.map((genre) => (
          <label
            key={genre}
            className={`genre-option ${
              selectedGenres.includes(genre) ? "selected" : ""
            }`}
          >
            <input
              type="checkbox"
              checked={selectedGenres.includes(genre)}
              onChange={() => handleGenreChange(genre)}
            />
            {genre}
          </label>
        ))}
      </div>
      <button className="next-button" onClick={handleNext}>
        Siguiente
      </button>
    </div>
  );
};

export default GenreSelection;
