import React from "react";
import HeaderLink from "./HeaderLink";

const Contact = () => {
  return (
    <div>
      <HeaderLink />
      <div style={{ padding: "40px", textAlign: "center" }}>
        <p>
          Para consultas o soporte, por favor contáctanos a través del siguiente
          formulario:
        </p>
        <a
          href="https://share.hsforms.com/14AvfPOUzRaSIZAciCOiEFwe7lvy"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#ffffff",
            backgroundColor: "#003366",
            padding: "10px 20px",
            textDecoration: "none",
            borderRadius: "5px",
            fontSize: "16px",
          }}
        >
          Formulario de Contacto
        </a>
      </div>
    </div>
  );
};

export default Contact;
