import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { useNavigate } from "react-router-dom";

import spotifyLogo from "../images/spotify-logo-black.png";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";
import "../blocks/Login.css";

import RegistroLogin from "./RegistroLogin";

const Login = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const checkSession = async () => {
  //     console.log("Verificando sesión activa...");
  //     const { data, error } = await supabase.auth.getSession();

  //     if (error) {
  //       console.error("Error obteniendo sesión:", error);
  //       return;
  //     }

  //     if (data.session) {
  //       console.log("Sesión detectada:", data.session);
  //       checkUserInDatabase(data.session.user.id);
  //     } else {
  //       console.log("No hay sesión activa.");
  //     }
  //   };

  //   checkSession();

  //   const { data: authListener } = supabase.auth.onAuthStateChange(
  //     async (event, session) => {
  //       console.log(`Evento de autenticación: ${event}`, session);
  //       if (session) {
  //         checkUserInDatabase(session.user.id);
  //       }
  //     }
  //   );

  //   return () => {
  //     authListener.subscription.unsubscribe();
  //   };
  // }, [navigate]);

  // const checkUserInDatabase = async (userId) => {
  //   console.log(
  //     `Verificando si el usuario ${userId} existe en la base de datos...`
  //   );

  //   const { data, error } = await supabase
  //     .from("users")
  //     .select("user_id")
  //     .eq("user_id", userId)
  //     .single();

  //   if (error) {
  //     console.error("Error verificando usuario en la base de datos:", error);
  //   } else if (data) {
  //     console.log(
  //       "Usuario encontrado en la base de datos. Redirigiendo a /myconcerts..."
  //     );
  //     navigate("/myconcerts");
  //   } else {
  //     console.log(
  //       "Usuario no encontrado en la base de datos. Redirigiendo a /liked-music-genres..."
  //     );
  //     navigate("/liked-music-genres");
  //   }
  // };

  // const isValidEmail = (email) => {
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // };

  // const handleSignInWithMagicLink = async () => {
  //   if (!isValidEmail(email)) return;

  //   setLoading(true);
  //   setMessage("");
  //   console.log(`Intentando iniciar sesión con Magic Link para: ${email}`);

  //   const { error } = await supabase.auth.signInWithOtp({ email });

  //   if (error) {
  //     console.error("Error enviando Magic Link:", error);
  //     setMessage(`Error: ${error.message}`);
  //   } else {
  //     console.log("Magic Link enviado correctamente.");
  //     setMessage(
  //       "Hemos enviado un enlace mágico a tu correo electrónico. Revisa tu bandeja de entrada."
  //     );
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="login-page">
      <header className="login-header">
        <img
          src={hyperlocalvibeslogo}
          alt="HyperLocalVibes Logo"
          className="login-logo"
        />
        <h1 className="header-title">HyperLocal Vibes</h1>
      </header>

      <main className="login-content">
        <h1 className="login-title">
          Descubre conciertos de bandas locales según la música que te gusta
        </h1>
        <h2 className="login-subtitle">
          En tu ciudad hay bandas con directos increíbles. ¡Forma parte de su
          historia! 🎤🔥
        </h2>

        {!message && (
          <div className="login-registration-group">
            <RegistroLogin />

            {/* <input
              type="email"
              placeholder="Introduce tu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />
            <button
              className="login-button"
              onClick={handleSignInWithMagicLink}
              disabled={!isValidEmail(email) || loading}
            >
              {loading ? "Enviando..." : "Accede con tu email"}
            </button> */}
          </div>
        )}

        {/* {message && <p className="message">{message}</p>} */}

        {/* <div className="separator">
          <span>O</span>
        </div>

        <button className="spotify-login">
          <img src={spotifyLogo} alt="Spotify Logo" className="spotify-icon" />
          Regístrate con Spotify
        </button> */}
      </main>

      <footer className="invite-bands">
        <p>
          ¿Tienes una banda?{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeedcvrQUtDQ8USAP-F-NTfK-TaP_xIa6GgQiek_W96e_KbFQ/viewform?usp=header"
            target="_blank"
            rel="noopener noreferrer"
          >
            Publica tus conciertos
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Login;
