import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Contact from "./Contact";
import UserProfile from "./UserProfile";
import Header from "./Header";
import RecoverPassword from "./RecoverPassword";

import GenreSelection from "./registration/GenreSelection";
import GenreDislike from "./registration/GenreDislike";
import FavoriteBands from "./registration/FavoriteBands";

import RecommendConcerts from "./RecommendConcerts";
import ConcertsList from "./ConcertsList";

import { supabase } from "./supabaseClient";
import "../blocks/App.css";

function App() {
  const [session, setSession] = useState(null);
  const [loadingSession, setLoadingSession] = useState(true);
  const [userExists, setUserExists] = useState(null); // Estado para verificar si el usuario está en la base de datos
  const [formData, setFormData] = useState({ genres: [], dislikedGenres: [] });
  const [recommendations, setRecommendations] = useState([]); // Estado para recomendaciones

  useEffect(() => {
    const checkSession = async () => {
      console.log("Verificando sesión en Supabase...");
      const { data, error } = await supabase.auth.getSession();

      if (error) {
        console.error("Error al obtener la sesión:", error);
        setLoadingSession(false);
        return;
      }

      if (data.session) {
        console.log("Sesión activa detectada:", data.session);
        setSession(data.session);
        checkUserInDatabase(data.session.user.id);
      } else {
        console.log("No hay sesión activa.");
        setLoadingSession(false);
      }
    };

    const checkUserInDatabase = async (userId) => {
      console.log(
        `Verificando si el usuario ${userId} está en la base de datos...`
      );

      const { data, error } = await supabase
        .from("users")
        .select("user_id")
        .eq("user_id", userId)
        .single();

      if (error) {
        console.warn(
          "Usuario no encontrado en la base de datos o error en la consulta:",
          error
        );
        setUserExists(false);
      } else {
        console.log("Usuario encontrado en la base de datos.");
        setUserExists(true);
      }
      setLoadingSession(false);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(`Evento de autenticación: ${event}`, session);
        setSession(session);
        if (session) {
          checkUserInDatabase(session.user.id);
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // Manejar el cierre de sesión
  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        if (error.message.includes("Auth session missing")) {
          setSession(null);
          window.localStorage.clear();
          return;
        }
        console.error("Error durante el cierre de sesión:", error.message);
      } else {
        setSession(null);
        window.localStorage.clear(); // Borrar almacenamiento local
      }
    } catch (err) {
      console.error("Error inesperado durante el cierre de sesión:", err);
      setSession(null);
      window.localStorage.clear();
    }
  };

  if (loadingSession) {
    return <h2>Cargando sesión...</h2>; // ⬅️ Evita que la app haga redirecciones innecesarias mientras verifica la sesión
  }

  return (
    <div>
      <div className="page">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                session ? (
                  userExists ? (
                    <Navigate to="/myconcerts" replace />
                  ) : (
                    <Navigate to="/liked-music-genres" replace />
                  )
                ) : (
                  <Login />
                )
              }
            />

            <Route
              path="/liked-music-genres"
              element={
                <>
                  {session && (
                    <Header session={session} handleLogout={handleLogout} />
                  )}
                  <GenreSelection
                    formData={formData}
                    setFormData={setFormData}
                  />
                </>
              }
            />
            <Route
              path="/disliked-music-genres"
              element={
                <>
                  {session && (
                    <Header session={session} handleLogout={handleLogout} />
                  )}
                  <GenreDislike formData={formData} setFormData={setFormData} />
                </>
              }
            />

            <Route
              path="/fav-bands"
              element={
                <>
                  {session && (
                    <Header session={session} handleLogout={handleLogout} />
                  )}
                  <FavoriteBands
                    formData={formData}
                    setFormData={setFormData}
                  />
                </>
              }
            />

            {/* <Route path="/fav-bands" element={<FavoriteBands />} /> */}

            {/* Ruta para mostrar los conciertos del usuario */}
            <Route
              path="/myconcerts"
              element={
                session ? (
                  <>
                    <Header session={session} handleLogout={handleLogout} />
                    <RecommendConcerts
                      session={session}
                      setRecommendations={setRecommendations}
                      handleLogout={handleLogout} // Asegura que handleLogout esté disponible
                    />
                    <ConcertsList
                      recommendations={recommendations}
                      session={session}
                    />
                  </>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/profile"
              element={
                session ? (
                  <>
                    <Header session={session} handleLogout={handleLogout} />
                    <UserProfile
                      session={session}
                      handleLogout={handleLogout}
                    />
                  </>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
      <div>
        <Footer />
        <Analytics />
      </div>
    </div>
  );
}

export default App;
