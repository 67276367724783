import React from "react";
import "../blocks/Footer.css";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Sección del logo y nombre */}
        <div className="footer-logo-container">
          <a href="/" className="footer-logo-link">
            <img
              className="footer-logo"
              src={hyperlocalvibeslogo}
              alt="HyperLocalVibes Logo"
            />
            <h3>HyperLocal Vibes</h3>
          </a>
        </div>

        {/* Enlaces del footer */}
        <div className="footer-links">
          <ul>
            <li>
              <button
                className="footer-button"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSeedcvrQUtDQ8USAP-F-NTfK-TaP_xIa6GgQiek_W96e_KbFQ/viewform?usp=header",
                    "_blank"
                  )
                }
              >
                Publica tus conciertos
              </button>
            </li>
            <li>
              <a href="/about">Sobre nosotros</a>
            </li>
            <li>
              <a href="/contact">Contacto</a>
            </li>
            <li>
              <a href="/privacy">Política de Privacidad</a>
            </li>
            <li>
              <a href="/terms">Términos del Servicio</a>
            </li>
          </ul>
        </div>
      </div>

      {/* Línea de copyright */}
      <div className="footer-bottom">
        {/* Texto descriptivo */}
        <div>
          HyperLocal Vibes | Descubre conciertos y bandas locales en tu ciudad
          según tu música favorita.
        </div>
        <p>
          {new Date().getFullYear()} HyperLocal Vibes. Todos los derechos
          reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
