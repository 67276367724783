import React, { useState, useEffect, useMemo } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import "../../blocks/registration/GenreSelection.css";

const GenreDislike = ({ formData = {}, setFormData }) => {
  const navigate = useNavigate();
  const genres = [
    "Pop",
    "Rock",
    "Indie",
    "Música Electrónica",
    "House",
    "Techno",
    "Reggaetón",
    "Trap",
    "Rap",
    "Hip-Hop",
    "R&B",
    "Funk",
    "Jazz",
    "Blues",
    "Soul",
    "Flamenco",
    "Música Clásica",
    "Country",
    "Reggae",
    "Salsa",
    "Cumbia",
    "Bachata",
    "K-Pop",
    "Metal",
    "Punk",
    "Folk",
    "Latin Alternative",
    "Bolero",
  ];

  const [dislikedGenres, setDislikedGenres] = useState(
    formData.dislikedGenres || []
  );
  const selectedGenres = useMemo(
    () => formData.genres || [],
    [formData.genres]
  );

  useEffect(() => {
    console.log(
      "Géneros seleccionados en GenreSelection (desde formData):",
      selectedGenres
    );
  }, [selectedGenres]);

  const handleGenreChange = (genre) => {
    setDislikedGenres((prev) =>
      prev.includes(genre) ? prev.filter((g) => g !== genre) : [...prev, genre]
    );
  };

  const getSession = async () => {
    console.log("Verificando sesión en Supabase...");
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    console.log("Sesión obtenida:", data);
    return data;
  };

  const updateUserData = async (userId) => {
    try {
      console.log(
        "Guardando disliked_genres en Supabase para usuario:",
        userId
      );
      const { error } = await supabase
        .from("users")
        .update({ disliked_genres: dislikedGenres.join(", ") })
        .eq("user_id", userId);

      if (error) {
        console.error("Error actualizando disliked genres en Supabase:", error);
        return false;
      }
      console.log("Géneros no gustados guardados en Supabase correctamente");
      return true;
    } catch (err) {
      console.error("Error en updateUserData:", err);
      return false;
    }
  };

  const handleNext = async () => {
    try {
      const data = await getSession();
      if (!data || !data.session || !data.session.user) {
        alert("No hay sesión activa. Por favor, inicia sesión.");
        navigate("/");
        return;
      }

      console.log("Géneros no gustados seleccionados:", dislikedGenres);
      if (typeof setFormData === "function") {
        setFormData({ ...formData, dislikedGenres });
      }

      const user = data.session.user;
      const success = await updateUserData(user.id);
      console.log("Resultado de updateUserData:", success);
      if (success) {
        console.log("Navegando a /fav-bands");
        navigate("/fav-bands");
      } else {
        alert("Hubo un problema al guardar los géneros no gustados.");
      }
    } catch (err) {
      console.error("Error en handleNext:", err);
      alert("Hubo un problema al procesar tu solicitud. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="genre-selection-container">
      <h2>¿Qué géneros prefieres que NO te recomendemos? 🤮</h2>
      <div className="genre-list">
        {genres.map((genre) => (
          <label
            key={genre}
            className={`genre-dislike ${
              dislikedGenres.includes(genre) ? "selected" : ""
            } ${selectedGenres.includes(genre) ? "disabled-genre" : ""}`}
          >
            <input
              type="checkbox"
              checked={dislikedGenres.includes(genre)}
              onChange={() => handleGenreChange(genre)}
              disabled={selectedGenres.includes(genre)}
            />
            {genre}
          </label>
        ))}
      </div>
      <button className="next-button" onClick={handleNext}>
        Siguiente
      </button>
    </div>
  );
};

export default GenreDislike;
