import React from "react";
import "../blocks/HeaderLink.css";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";

const HeaderLink = () => {
  return (
    <header className="header-link">
      <a href="/" className="header-link-logo">
        <img src={hyperlocalvibeslogo} alt="HyperLocalVibes Logo" />
        <h1>HyperLocal Vibes</h1>
      </a>
    </header>
  );
};

export default HeaderLink;
