import React, { useEffect, useState, useRef } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/FeedbackButton.css";

import thumbsupWhite from "../images/thumbs-up.svg";
import thumbsdownWhite from "../images/thumbs-down.svg";
import thumbsupBlack from "../images/thumbs-up-black.svg";
import thumbsdownBlack from "../images/thumbs-down-black.svg";

const fetchFeedbackData = async (concertId, userId) => {
  try {
    // Obtener la valoración actual del usuario
    const { data, error } = await supabase
      .from("user_concert_recommendations")
      .select("recommendation_feedback")
      .eq("user_id", userId)
      .eq("concert_id", concertId)
      .single();

    if (error && error.code !== "PGRST116") {
      console.error("Error fetching feedback data:", error);
      throw error;
    }

    return data?.recommendation_feedback ?? 0; // Valor por defecto: 0 (sin valorar)
  } catch (error) {
    console.error("Unexpected error fetching feedback data:", error);
    return 0; // Retorna 0 en caso de error para evitar fallos en la UI
  }
};

const updateFeedbackInDatabase = async (feedbackValue, concertId, userId) => {
  try {
    // Actualizar únicamente el campo `recommendation_feedback` en filas existentes
    const { error } = await supabase
      .from("user_concert_recommendations")
      .update({ recommendation_feedback: feedbackValue })
      .eq("user_id", userId)
      .eq("concert_id", concertId);

    if (error) {
      console.error("Error updating feedback in database:", error);
      throw new Error("Unable to update feedback in database.");
    }
  } catch (error) {
    console.error("Unexpected error updating feedback:", error);
  }
};

const FeedbackButton = ({ concertId, session }) => {
  const [feedback, setFeedback] = useState(0); // 0 = sin valorar, 1 = thumbs up, -1 = thumbs down
  const isProcessing = useRef(false);

  // Extrae el userId de session de forma segura
  const userId = session?.user?.id;

  useEffect(() => {
    if (!userId || !concertId) return;

    const fetchData = async () => {
      try {
        const feedbackValue = await fetchFeedbackData(concertId, userId);
        setFeedback(feedbackValue);
      } catch (error) {
        console.error("Error loading feedback data:", error);
      }
    };

    fetchData();
  }, [concertId, userId]);

  const handleFeedback = async (newFeedback) => {
    if (isProcessing.current || !userId || !concertId) return;
    isProcessing.current = true;

    try {
      const feedbackValue = feedback === newFeedback ? 0 : newFeedback; // Alternar feedback o volver a 0
      await updateFeedbackInDatabase(feedbackValue, concertId, userId);
      setFeedback(feedbackValue);
    } catch (error) {
      console.error("Error toggling feedback:", error);
    } finally {
      isProcessing.current = false;
    }
  };

  if (!userId) {
    return (
      <span className="feedback-disabled">Inicia sesión para valorar</span>
    );
  }

  return (
    <div className="feedback-icons">
      <img
        src={feedback === 1 ? thumbsupBlack : thumbsupWhite}
        alt="Thumbs Up"
        className="feedback-icon"
        onClick={() => handleFeedback(1)}
      />
      <img
        src={feedback === -1 ? thumbsdownBlack : thumbsdownWhite}
        alt="Thumbs Down"
        className="feedback-icon"
        onClick={() => handleFeedback(-1)}
      />
    </div>
  );
};

export default FeedbackButton;
