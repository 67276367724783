// ConcertsList.js: Componente funcional para mostrar una lista de conciertos y permitir al usuario filtrarlos por fecha, género, recomendación, local y nombre de la banda.

import React, { useEffect, useState, useRef } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/ConcertsList.css";
import whatsapplogo from "../images/Digital_Glyph_Black.png";
import LikeButton from "./LikeButton";
import FeedbackButton from "./FeedbackButton";
import SpotifyLogo from "../images/spotify-logo.png";

const ConcertsList = ({ recommendations, session }) => {
  const [concerts, setConcerts] = useState([]);
  const [filteredConcerts, setFilteredConcerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para los filtros
  const [dateFilter, setDateFilter] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const [recommendationFilter, setRecommendationFilter] = useState("");
  const [venueFilter, setVenueFilter] = useState("");
  const [bandFilter, setBandFilter] = useState(""); // Estado para el filtro del nombre de la banda

  useEffect(() => {
    const fetchConcerts = async () => {
      try {
        const today = new Date().toISOString().split("T")[0];
        const { data, error: fetchError } = await supabase
          .from("concerts")
          .select(
            `
            *,
            concert_band (
              band_id,
              bands (
                band_name,
                band_spotify_url
              )
            )
          `
          )
          .gte("day", today)
          .order("day", { ascending: true }) // Ordenar por fecha ascendente
          .order("hour", { ascending: true }); // Ordenar por hora ascendente

        if (fetchError) throw fetchError;

        // Transform the data to include bands in a simpler format
        const transformedConcerts = data.map((concert) => ({
          ...concert,
          bands: concert.concert_band.map((cb) => ({
            name: cb.bands.band_name,
            spotify_url: cb.bands.band_spotify_url,
          })),
        }));

        transformedConcerts.forEach((concert) => {});

        setConcerts(transformedConcerts);
        setFilteredConcerts(transformedConcerts); // Inicialmente, todos los conciertos están visibles
      } catch (error) {
        console.error("Error fetching concerts data:", error);
        setError("Error fetching concerts data.");
      } finally {
        setLoading(false);
      }
    };

    fetchConcerts();
  }, []);

  // Función para manejar los filtros
  useEffect(() => {
    const filterConcerts = () => {
      let filtered = concerts;

      if (dateFilter) {
        filtered = filtered.filter((concert) => concert.day === dateFilter);
      }

      if (genreFilter) {
        filtered = filtered.filter((concert) =>
          concert.genre.toLowerCase().includes(genreFilter.toLowerCase())
        );
      }

      if (recommendationFilter) {
        filtered = filtered.filter((concert) => {
          const match = recommendations.find(
            (rec) => rec.concert_id === concert.id
          );
          return match?.score === recommendationFilter;
        });
      }

      if (venueFilter) {
        filtered = filtered.filter((concert) =>
          concert.venue.toLowerCase().includes(venueFilter.toLowerCase())
        );
      }

      if (bandFilter) {
        filtered = filtered.filter((concert) =>
          concert.bands.some((band) =>
            band.name.toLowerCase().includes(bandFilter.toLowerCase())
          )
        );
      }

      setFilteredConcerts(filtered);
    };

    filterConcerts();
  }, [
    dateFilter,
    genreFilter,
    recommendationFilter,
    venueFilter,
    concerts,
    recommendations,
    bandFilter,
  ]);

  // Obtener el emoji para la recomendación
  const getEmoji = (concert) => {
    const match = recommendations.find((rec) => rec.concert_id === concert.id);
    switch (match?.score) {
      case "extreme":
        return "🔥🔥🔥";
      case "high":
        return "🔥🔥";
      case "medium":
        return "🔥";
      case "low":
        return "😴";
      default:
        return <span className="loading-emoji">🫥</span>;
    }
  };

  // Obtener la razón de la recomendación
  const getReason = (concert) => {
    const match = recommendations.find((rec) => rec.concert_id === concert.id);
    return (
      match?.reason ||
      "Dame un momento para pensarlo... ¡tu gustos musicales son interesantes!"
    );
  };

  // Formatear la fecha en español
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: "long", day: "numeric", month: "long" };
    return date.toLocaleDateString("es-ES", options);
  };

  // Generar enlace de WhatsApp
  const generateWhatsAppLink = (concert) => {
    const message = `

  ¿Te apuntas a este concierto? El ${formatDate(concert.day)}, ${
      concert.bandname
    } actúa en ${concert.venue}. ${
      concert.price === 0
        ? `¡Y es gratis!`
        : `Las entradas están a ${concert.price}€.`
    } 
    
  Lo he encontrado gratis en https://www.hyperlocalvibes.com, la web para descubrir bandas locales que encajan con tu música favorita en Spotify. Pruébalo y flipa.
    
  `;
    return `https://wa.me/?text=${encodeURIComponent(message)}`;
  };

  if (loading)
    return (
      <p className="loading-message">
        <span className="loading-emoji"></span>{" "}
        {/* Por ahora no mostramos mensaje porque va rápido */}
      </p>
    );
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="concerts-container">
      {/* <h2 className="cards-title">Descubre próximos conciertos</h2> */}

      {/* Filtros */}

      <div className="filters-container">
        <div>
          <label>
            Fecha:
            <input
              type="date"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </label>
        </div>

        <div>
          <label>
            Recomendación:
            <select
              value={recommendationFilter}
              onChange={(e) => setRecommendationFilter(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="extreme">🔥🔥🔥</option>
              <option value="high">🔥🔥</option>
              <option value="medium">🔥</option>
              <option value="low">😴</option>
            </select>
          </label>
        </div>
      </div>

      {/* Lista de conciertos */}
      <div className="cards-grid">
        {filteredConcerts.map((concert) => (
          <div key={concert.id} className="concert-card">
            <div className="card-header">
              <span className="concert-date">{formatDate(concert.day)}</span>
              <span className="concert-emoji">{getEmoji(concert)}</span>
            </div>
            <div className="card-body">
              <h3 className="concert-band">
                {concert.bands.map((band) => band.name).join(" + ")}
              </h3>
              <p className="concert-genre">
                <strong>Género:</strong> {concert.genre}
              </p>
              <p className="concert-venue">
                <strong>Local:</strong> {concert.venue}
              </p>
              <p className="concert-time">
                <strong>Hora:</strong> {concert.hour}
              </p>
              <p className="concert-price">
                Precio: {concert.price === 0 ? "Gratis" : `${concert.price} €`}
              </p>
              <p className="concert-reason">{getReason(concert)}</p>
              <div className="bands-container">
                {concert.bands.some((band) => band.spotify_url) && ( // Solo renderizar si hay al menos un enlace
                  <>
                    <p className="spotify-title">
                      Descubre su música en Spotify:
                    </p>
                    {concert.bands.map(
                      (band, index) =>
                        band.spotify_url && (
                          <div key={index} className="band-item">
                            <a
                              href={band.spotify_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="spotify-link"
                            >
                              <img
                                src={SpotifyLogo}
                                alt="Spotify Logo"
                                className="spotify-logo"
                              />
                              {band.name}
                            </a>
                          </div>
                        )
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="card-bottom">
              <div className="card-actions">
                <div className="like-wrapper">
                  <LikeButton concertId={concert.id} session={session} />
                </div>
                <a
                  href={generateWhatsAppLink(concert)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsapp-share-button"
                >
                  <img
                    src={whatsapplogo}
                    alt="WhatsApp"
                    className="whatsapp-icon"
                  />
                  <span>Compartir</span>
                </a>
              </div>
              <div className="card-footer">
                <span className="feedback-text">¿Recomendación acertada?</span>
                <div className="feedback-icons">
                  <FeedbackButton concertId={concert.id} session={session} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConcertsList;
