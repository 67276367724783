import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { useNavigate } from "react-router-dom";
import "../blocks/UserProfile.css";

const UserProfile = ({ session }) => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const userId = session?.user?.id;
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) fetchUserData();
  }, [userId]);

  const fetchUserData = async () => {
    try {
      const { data: user, error } = await supabase
        .from("users")
        .select("name, email, top_artists, top_genres, disliked_genres")
        .eq("user_id", userId)
        .single();

      if (error) throw error;
      setUserData(user);
    } catch (err) {
      setError("Error al cargar los datos del usuario.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({
          name: userData.name,
          top_artists: userData.top_artists,
          top_genres: userData.top_genres,
          disliked_genres: userData.disliked_genres,
        })
        .eq("user_id", userId);

      if (error) throw error;

      setMessage("¡Datos actualizados con éxito! ✅");
      setIsEditing(false);
      setTimeout(() => setMessage(""), 3000);
    } catch (err) {
      setError("Error al actualizar los datos. Inténtalo de nuevo.");
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword) return;

    if (newPassword.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres.");
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setPasswordError("Error al cambiar la contraseña: " + error.message);
    } else {
      setPasswordMessage("¡Contraseña actualizada con éxito! ✅");
      setNewPassword("");
      setTimeout(() => setPasswordMessage(""), 3000);
    }
  };

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar tu cuenta? Esta acción es irreversible."
    );

    if (!confirmDelete) return;

    try {
      await supabase.from("users").delete().eq("user_id", userId);
      await supabase.auth.signOut();
      alert("Cuenta eliminada correctamente.");
      navigate("/");
    } catch (err) {
      setError("Ocurrió un problema al eliminar tu cuenta.");
    }
  };

  if (!userData) return <p>Cargando datos del usuario...</p>;

  return (
    <div className="profile-container">
      <main className="profile-content">
        <h2>Esto es lo que sabemos de ti</h2>
        <p>
          <span className="styled-span">
            Recopilamos tus gustos musicales para escucharte mejor.
          </span>
          <br />
          <span className="styled-span">
            Usamos esta info solo para recomendarte conciertos que te flipen de
            verdad.
          </span>
        </p>

        {error && <p className="error">{error}</p>}
        {message && <p className="success-message">{message}</p>}

        <ul className="profile-details">
          <li>
            <strong>Tu nombre:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="name"
                value={userData.name || ""}
                onChange={handleInputChange}
              />
            ) : (
              userData.name || "Ni idea 🤷‍♂️"
            )}
          </li>
          <li>
            <strong>Tu correo electrónico:</strong> {userData.email}
          </li>
          <li>
            <strong>Contraseña:</strong>{" "}
            {isEditing ? (
              <input
                type="password"
                className="password-input"
                placeholder="Nueva contraseña"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            ) : (
              "******"
            )}
          </li>
          <li>
            <strong>Tus artistas favoritos:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="top_artists"
                value={userData.top_artists || ""}
                onChange={handleInputChange}
              />
            ) : (
              userData.top_artists || "¡Cuéntanos tus gustos!"
            )}
          </li>
          <li>
            <strong>Tus géneros preferidos:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="top_genres"
                value={userData.top_genres || ""}
                onChange={handleInputChange}
              />
            ) : (
              userData.top_genres || "¡Esto está muy vacío! 🎸"
            )}
          </li>
          <li>
            <strong>Los géneros musicales que no te molan:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="disliked_genres"
                value={userData.disliked_genres || ""}
                onChange={handleInputChange}
              />
            ) : (
              userData.disliked_genres ||
              "¡Nos ayudará a recomendarte mejores conciertos! 🤘"
            )}
          </li>
        </ul>

        {isEditing && (
          <button className="password-button" onClick={handleChangePassword}>
            Guardar nueva contraseña
          </button>
        )}
        {passwordMessage && (
          <p className="success-message">{passwordMessage}</p>
        )}
        {passwordError && <p className="error">{passwordError}</p>}

        {isEditing ? (
          <button className="save-button" onClick={handleSaveChanges}>
            Guardar cambios
          </button>
        ) : (
          <button className="edit-button" onClick={() => setIsEditing(true)}>
            Editar perfil
          </button>
        )}

        <button
          className="go-to-concerts-button"
          onClick={() => navigate("/myconcerts")}
        >
          Vuelve a los conciertos
        </button>

        <div className="danger-zone">
          <h3 className="danger-title">Zona de Peligro</h3>
          <p>
            <span className="styled-span-red">
              Si decides decirnos adiós, lo aceptamos (aunque se nos rompa un
              poquito el corazón 💔).
            </span>
          </p>
          <button
            className="delete-account-button"
            onClick={handleDeleteAccount}
          >
            Eliminar mi cuenta
          </button>
        </div>
      </main>
    </div>
  );
};

export default UserProfile;
