// supabaseClient.js
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseServiceRoleKey = process.env.REACT_APP_SUPABASE_SERVICE_ROLE_KEY;

const supabase = createClient(
  supabaseUrl,
  process.env.NODE_ENV === "development"
    ? supabaseServiceRoleKey
    : supabaseAnonKey
);

const supabaseService = createClient(supabaseUrl, supabaseServiceRoleKey);

export { supabase, supabaseService };
