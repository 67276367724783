import React from "react";
import "../blocks/Header.css";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";
import { useNavigate } from "react-router-dom";

const Header = ({ session, handleLogout }) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="header-container">
        <div className="user-data-branding" style={{ cursor: "pointer" }}>
          <img
            src={hyperlocalvibeslogo}
            alt="HyperLocalVibes Logo"
            className="user-data-logo"
          />
          <h2 className="user-data-brand">HyperLocal Vibes</h2>
        </div>

        <div className="user-data-information">
          <button
            className="user-data-profile-button"
            onClick={() => navigate("/profile")}
          >
            Mi perfil
          </button>

          <button className="user-data-logout-button" onClick={handleLogout}>
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
