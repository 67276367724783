import React, { useEffect, useState, useRef } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/LikeButton.css";

import likeiconred from "../images/lovered.svg";
import likeiconwhite from "../images/lovewhite.svg";

// Función para obtener los datos iniciales de "like_count" y "liked"
const fetchLikeData = async (concertId, userId) => {
  try {
    // Obtener datos del concierto
    const { data: concertData, error: concertError } = await supabase
      .from("concerts")
      .select("like_count")
      .eq("id", concertId)
      .single();

    if (concertError && concertError.code !== "PGRST116") {
      console.error("Error fetching concert data:", concertError);
      throw concertError;
    }

    // Obtener el estado del "like" del usuario
    const { data: userLikeData, error: userLikeError } = await supabase
      .from("user_concert_recommendations")
      .select("liked")
      .eq("user_id", userId)
      .eq("concert_id", concertId)
      .single();

    if (userLikeError && userLikeError.code !== "PGRST116") {
      console.error("Error fetching user like data:", userLikeError);
      throw userLikeError;
    }

    return {
      likeCounter: concertData?.like_count || 0,
      liked: userLikeData?.liked || false,
    };
  } catch (error) {
    console.error("Unexpected error fetching like data:", error);
    return {
      likeCounter: 0,
      liked: false,
    }; // Devuelve valores por defecto para evitar errores en la UI
  }
};

// Función para actualizar el contador de likes en la tabla "concerts"
const updateLikeCount = async (newLikeCount, concertId) => {
  try {
    const { error, data } = await supabase
      .from("concerts")
      .update({ like_count: newLikeCount })
      .eq("id", concertId);

    if (error) {
      console.error("Error updating like_count in concerts:", error);
      return false;
    }

    return true;
  } catch (error) {
    console.error("Unexpected error updating like_count:", error);
    return false;
  }
};

// Función para alternar el estado "liked" en "user_concert_recommendations"
const toggleLikeInDatabase = async (liked, likeCounter, concertId, userId) => {
  try {
    // Actualizar el campo "liked" en `user_concert_recommendations`
    const { error: updateLikedError } = await supabase
      .from("user_concert_recommendations")
      .update({ liked: !liked })
      .eq("user_id", userId)
      .eq("concert_id", concertId);

    if (updateLikedError) {
      console.error(
        "Error updating 'liked' in user_concert_recommendations:",
        updateLikedError
      );
      return false;
    }

    // Calcular el nuevo valor de like_count
    const newLikeCount = liked ? likeCounter - 1 : likeCounter + 1;

    // Llamar a la función para actualizar el like_count
    const result = await updateLikeCount(newLikeCount, concertId);
    return result;
  } catch (error) {
    console.error("Unexpected error toggling like in database:", error);
    return false;
  }
};

const LikeButton = ({ concertId, session }) => {
  const [liked, setLiked] = useState(false);
  const [likeCounter, setLikeCounter] = useState(0);
  const isProcessing = useRef(false);

  // Extrae el userId de session de forma segura
  const userId = session?.user?.id;

  useEffect(() => {
    if (!userId || !concertId) return;

    const fetchData = async () => {
      try {
        const { likeCounter, liked } = await fetchLikeData(concertId, userId);
        setLikeCounter(likeCounter);
        setLiked(liked);
      } catch (error) {
        console.error("Error loading like data:", error);
      }
    };

    fetchData();
  }, [concertId, userId]);

  const toggleLike = async () => {
    if (isProcessing.current || !userId || !concertId) return;
    isProcessing.current = true;

    try {
      const success = await toggleLikeInDatabase(
        liked,
        likeCounter,
        concertId,
        userId
      );
      if (success) {
        setLiked((prev) => !prev); // Alternar el estado local
        setLikeCounter((prev) => (liked ? prev - 1 : prev + 1)); // Ajustar el contador localmente
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    } finally {
      isProcessing.current = false;
    }
  };

  if (!userId) {
    return <span className="like-disabled">Inicia sesión para dar like</span>;
  }

  return (
    <div className="like-section">
      <img
        src={liked ? likeiconred : likeiconwhite}
        alt="Like Icon"
        className="like-icon"
        onClick={toggleLike}
      />
      <span className="like-count">{likeCounter}</span>
    </div>
  );
};

export default LikeButton;
