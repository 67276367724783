import React from "react";
import HeaderLink from "./HeaderLink";
import "../blocks/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <HeaderLink />
      <div className="privacy-policy">
        <p>
          En HyperLocalVibes, respetamos tu privacidad. Recopilamos únicamente
          los datos necesarios para personalizar tu experiencia y recomendar la
          mejor música local. Tus datos se almacenan de forma segura y nunca se
          comparten sin tu consentimiento. Para más información, por favor
          contáctanos a través de{" "}
          <a
            href="https://share.hsforms.com/14AvfPOUzRaSIZAciCOiEFwe7lvy"
            target="_blank"
            rel="noopener noreferrer"
          >
            este formulario
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
