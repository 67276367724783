import React from "react";
import HeaderLink from "./HeaderLink";
import "../blocks/AboutUs.css";

const AboutUs = () => {
  return (
    <div>
      <HeaderLink />
      <div className="about-us">
        <p>
          HyperLocalVibes conecta a los amantes de la música con bandas y
          conciertos locales basados en sus preferencias de Spotify. Nuestra
          misión es facilitar y hacer divertida la experiencia de descubrir
          música local.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
