import React from "react";
import HeaderLink from "./HeaderLink";
import "../blocks/TermsOfService.css";

const TermsOfService = () => {
  return (
    <div>
      <HeaderLink />
      <div className="terms-of-service">
        <p>
          Al usar HyperLocalVibes, aceptas nuestros términos y condiciones. La
          plataforma está diseñada para uso personal. Cualquier uso indebido,
          redistribución de datos o violación de nuestras políticas puede
          resultar en la suspensión de tu cuenta. Para más información, por
          favor contáctanos a través de{" "}
          <a
            href="https://share.hsforms.com/14AvfPOUzRaSIZAciCOiEFwe7lvy"
            target="_blank"
            rel="noopener noreferrer"
          >
            este formulario
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
