import { useState } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/RecoverPassword.css";
import HeaderLink from "./HeaderLink";

export default function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError("");

    const { error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      setError("Error: " + error.message);
    } else {
      setMessage(
        `Hemos enviado un enlace para restablecer tu contraseña a ${email}.`
      );
    }

    setLoading(false);
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+.[^\s@]+$/.test(email);

  return (
    <>
      <HeaderLink />
      <div className="password-reset-container">
        <h2 className="reset-title">Recuperar contraseña</h2>
        <p className="reset-instructions">
          Introduce tu correo y te enviaremos un enlace para restablecer tu
          contraseña.
        </p>

        <form onSubmit={handlePasswordReset} className="reset-form">
          <input
            type="email"
            className="reset-input"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            type="submit"
            className="reset-button"
            disabled={!isValidEmail(email) || loading}
          >
            {loading ? "Enviando..." : "Enviar enlace"}
          </button>
        </form>

        {message && <p className="reset-message success">{message}</p>}
        {error && <p className="reset-message error">{error}</p>}
      </div>
    </>
  );
}
